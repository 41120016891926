import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React from 'react';

import {Box} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import Modal from 'components/basic-components/Modal';
import ScrollBox from 'components/basic-components/ScrollBox';

import BPRWidget from '../BPRWidget';
import styles from './BPRModal.module.scss';

const BPRModal = ({
  modalOpen,
  setModalOpen,
  presetStation,
  presetReturnStation,
  presetDistance,
  presetGroup,
  presetType,
  presetCategory,
  presetVip,
  preferredVehicle,
  minDate,
  maxDate,
  submitHandler,
  maxRentalDuration,
  layout = 'base',
  hideOneWay = false,
  isTeaser = false,
}: {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  presetStation?: string;
  presetReturnStation?: string;
  presetDistance?: any;
  presetGroup?: string;
  presetType?: string;
  presetCategory?: string;
  presetVip?: boolean;
  preferredVehicle?: string;
  minDate?: string;
  maxDate?: string;
  submitHandler?: Function;
  maxRentalDuration?: number;
  layout?: 'base' | 'klm';
  hideOneWay?: boolean;
  isTeaser?: boolean;
}) => {
  const {t} = useTranslation('loginModal');
  return (
    <Modal disableScrollLock={false} open={modalOpen} onClose={() => setModalOpen(!modalOpen)}>
      <Box className={styles.modalContainer}>
        <ScrollBox className={styles.content}>
          <BPRWidget
            onSubmitHandler={submitHandler}
            variant="modal"
            presetStation={presetStation}
            presetReturnStation={presetReturnStation}
            presetGroup={presetGroup}
            presetDistance={presetDistance}
            presetCategory={presetCategory}
            preferredVehicle={preferredVehicle}
            presetType={presetType}
            presetVip={presetVip}
            minDate={minDate}
            maxDate={maxDate}
            maxRentalDuration={maxRentalDuration}
            layout={layout}
            hideOneWay={hideOneWay}
            isTeaser={isTeaser}
          />
        </ScrollBox>
        <button
          aria-label={t('closeModal')}
          type="button"
          onClick={e => {
            e.preventDefault(), setModalOpen(false);
          }}
          className={styles.closeButton}
        >
          <CustomIcon name="close" className={styles.icon} />
        </button>
      </Box>
    </Modal>
  );
};

export default BPRModal;
