import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import Image from 'next/image';
import React, {useEffect, useState} from 'react';

import {Group} from 'lib/api/backend.schemas';
import {
  SubcomponentsButtonComponent,
  TypoComponentsCarTeaserSectionImage,
  TypoComponentsCarTeaserSectionSubTitleLevel,
  TypoComponentsCarTeaserSectionTitleLevel,
} from 'lib/api/strapi';

import {palette} from 'styles/mui/scss';

import {Box, Typography} from 'components/basic-components';
import {ButtonWithConfig} from 'components/basic-components/Button/ButtonWithConfig';
import StrapiLink from 'components/basic-components/StrapiLink';

import styles from './CarTeaser.module.scss';

const CarTeaserMobileContent = ({titleLevel, title, button, subtitle, subTitleLevel}) => {
  return (
    <Box className={styles.imageContentWrapperMobile}>
      <Typography
        component={titleLevel}
        className={styles.titleMobile}
        sx={{typography: {xxs: 'teaser', sm: 'h1'}}}
      >
        {title}
      </Typography>
      <Typography variant="buttonCards" component={subTitleLevel} className={styles.subtitleMobile}>
        {subtitle}
      </Typography>
      <ButtonWithConfig buttonProps={button} sx={{marginTop: '10px'}} />
    </Box>
  );
};

const CarTeaserMobile = ({
  groups,
  title,
  titleLevel,
  subtitle,
  subTitleLevel,
  image,
  button,
  handleCTA,
}: {
  groups: Group[];
  title: string;
  titleLevel: TypoComponentsCarTeaserSectionTitleLevel;
  subtitle: string;
  subTitleLevel: TypoComponentsCarTeaserSectionSubTitleLevel;
  image: TypoComponentsCarTeaserSectionImage;
  button: SubcomponentsButtonComponent;
  handleCTA: any;
}) => {
  const [chipHeight, setChipHeight] = useState('0px');
  const {t} = useTranslation('strapiComponents');
  const {theme} = useBackgroundStyleConfigs();
  useEffect(() => {
    let maxHeight = 0;
    groups.forEach(dataItem => {
      let height = 0;
      if (dataItem.powerInclusive && dataItem.electro) {
        height = 55;
      } else if (dataItem.powerInclusive || dataItem.electro) {
        height = 25;
      }
      maxHeight = Math.max(maxHeight, height);
    });
    setChipHeight(`${maxHeight}px`);
  }, [groups]);

  return (
    <>
      <Box sx={{position: 'relative'}}>
        <Box className={styles.backgroundMobile}>
          {button?.page?.data?.attributes?.fullSlug || button?.buttonLink ? (
            <StrapiLink
              style={{
                display: 'block',
                height: '100%',
                width: '100%',
                zIndex: 2,
                position: 'relative',
              }}
              buttonProps={button}
            />
          ) : null}
        </Box>
        <Image
          className={styles.imageMobile}
          src={image?.data?.attributes?.url}
          alt=""
          height={image?.data?.attributes?.height}
          width={image?.data?.attributes?.width}
        />
        <Box
          sx={{
            color: 'inherit',
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1,
            width: '100%',
          }}
        >
          <CarTeaserMobileContent
            titleLevel={titleLevel}
            title={title}
            button={button}
            subtitle={subtitle}
            subTitleLevel={subTitleLevel}
          />
        </Box>
      </Box>
      <Box className={styles.groupsWrapperMobile}>
        {groups?.map(group => {
          return (
            <Box key={group?.id} className={styles.groupContainerMobile}>
              <button
                onClick={() => handleCTA(group)}
                className={styles.groupWrapperMobile}
                style={{
                  backgroundColor: theme === 'grey' ? palette.colorWhite : palette.colorLightGrey,
                }}
              >
                <Box sx={{height: chipHeight}} className={styles.chipsMobile}>
                  {group?.electro ? (
                    <Typography className={styles.electro} variant="chip">
                      {t('electric')}
                    </Typography>
                  ) : null}
                  {group?.powerInclusive ? (
                    <Typography className={styles.electricity} variant="chip">
                      {t('electricityIncluded')}
                    </Typography>
                  ) : null}
                </Box>
                <Image
                  style={{height: 'auto', width: '100%', display: 'block'}}
                  src={group?.image?.small}
                  width={500}
                  height={500}
                  alt=""
                  priority
                />
                <Typography className={styles.groupNameMobile}>
                  {t('group')} {group?.id}
                </Typography>
                <Typography sx={{lineHeight: '24px'}} variant="buttonCards">
                  {t('forExample')} {group?.description}
                </Typography>
              </button>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default CarTeaserMobile;
